<template>
  <div class="pt-3">
    <router-link class="mb-3 ml-5 a-highlight a-pointer" :to="registrationLink"
      >&lt; Späť na registráciu</router-link
    >
    <iframe
      :src="url + '#toolbar=0&navpanes=0'"
      class="terms-iframe mt-3"
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: "/pdf/terms.pdf",
    },
    registrationLink: {
      type: String,
      default: "/prihlasit-sa",
    },
  },
};
</script>

<style scoped>
.terms-iframe {
  width: 100%;
  height: 100vh;
}
</style>
